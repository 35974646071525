html {
  box-sizing: border-box; }

*,
*::before,
*::after {
  box-sizing: inherit; }

body {
  color: #444;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  overflow: hidden; }

header,
nav,
footer {
  display: block; }

img {
  border-width: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

a {
  padding: 8px; }
  a:hover {
    opacity: 0.8; }

h1 {
  font-size: 28px;
  margin: 30px 0;
  text-transform: uppercase; }

h2 {
  font-size: 18px; }

p {
  line-height: 1.5em; }

sub,
sup {
  font-family: 'Courier New';
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

textarea {
  overflow: auto; }

button {
  overflow: visible; }

.button {
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 18px;
  font-weight: 700;
  height: 44px;
  margin: 10px auto 0;
  min-width: 180px;
  text-transform: uppercase; }
  .button:hover {
    opacity: 0.8; }
  .button[disabled] {
    cursor: not-allowed;
    opacity: 0.4; }

.sm-button {
  background: #eee;
  border: none;
  border-radius: 2px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  margin: 0;
  min-width: 80px;
  text-transform: uppercase; }
  .sm-button:hover {
    opacity: 0.8; }
  .sm-button[disabled] {
    cursor: not-allowed;
    opacity: 0.4; }

.clc-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
  width: 100%; }
  .clc-container #skip {
    z-index: 10; }
    .clc-container #skip a {
      background: #fff;
      border: 1px solid #444;
      color: inherit;
      left: 12px;
      padding: 8px;
      position: absolute;
      text-decoration: none;
      transition: top 0.3s ease-out;
      top: -40px; }
      .clc-container #skip a:focus {
        transition: top 0.3s ease-in;
        top: 0; }
  .clc-container .loading {
    max-width: 860px;
    margin: 0 auto; }
  .clc-container .clc-header {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2); }
    .clc-container .clc-header .header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 20px 30px;
      position: relative; }
      .clc-container .clc-header .header .header-logo {
        display: flex;
        max-height: 52px; }
        .clc-container .clc-header .header .header-logo a {
          display: inline-block;
          padding: 0; }
          .clc-container .clc-header .header .header-logo a img {
            height: 100%;
            width: 100%;
            object-fit: scale-down; }
      .clc-container .clc-header .header .header-nav .nav {
        display: flex;
        text-transform: uppercase; }
        .clc-container .clc-header .header .header-nav .nav a {
          font-weight: 700;
          text-decoration: none; }
        .clc-container .clc-header .header .header-nav .nav span:not(.special) a {
          border-bottom: 5px solid #ffffff;
          color: inherit;
          padding: 15px 8px;
          margin: 0 4px;
          transition: border-bottom-color 0.3s linear, color 0.3s linear;
          white-space: nowrap; }
          .clc-container .clc-header .header .header-nav .nav span:not(.special) a.selected {
            border-width: 5px;
            border-style: solid; }
        .clc-container .clc-header .header .header-nav .nav span.special a {
          border-radius: 18px;
          color: #fff;
          background: #777;
          padding: 8px 14px;
          margin: -8px 0 0 12px;
          transition: background-color 0.3s linear, color 0.3s linear; }
      .clc-container .clc-header .header .compact-nav .menu {
        background: #fff;
        border: none;
        cursor: pointer;
        position: absolute;
        right: 6px;
        top: 16px; }
        .clc-container .clc-header .header .compact-nav .menu .material-icons.md-36 {
          color: #444;
          font-size: 36px; }
      .clc-container .clc-header .header .compact-nav #compact-nav {
        background: #f5f5f5;
        border-top: 1px solid #ddd;
        box-shadow: -2px 2px 5px 0px rgba(0, 0, 0, 0.2);
        max-height: 0;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        right: 0;
        top: 68px;
        transition: max-height 0.2s ease-out, opacity 1s ease-out;
        width: 100%;
        z-index: 5; }
        .clc-container .clc-header .header .compact-nav #compact-nav .nav {
          display: flex;
          flex-direction: column;
          justify-content: center; }
          .clc-container .clc-header .header .compact-nav #compact-nav .nav span a {
            align-items: center;
            border-bottom: 1px solid #ddd;
            color: inherit;
            display: flex;
            font-weight: 700;
            justify-content: space-between;
            padding: 12px;
            text-transform: uppercase;
            text-decoration: none; }
            .clc-container .clc-header .header .compact-nav #compact-nav .nav span a:hover {
              color: #2b2b2b;
              background: gainsboro; }
        .clc-container .clc-header .header .compact-nav #compact-nav.is-active {
          max-height: 300px;
          opacity: 1;
          transition: max-height 0.2s ease-in; }
  .clc-container .clc-content {
    height: 100vh;
    overflow-x: scroll;
    position: relative; }
    .clc-container .clc-content .container-content {
      text-align: left; }
      .clc-container .clc-content .container-content a {
        color: #444;
        padding: 0; }
      .clc-container .clc-content .container-content hr {
        border: 0;
        height: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        padding-bottom: 10px; }
      .clc-container .clc-content .container-content h2,
      .clc-container .clc-content .container-content h3,
      .clc-container .clc-content .container-content h4 {
        color: #777;
        text-align: left;
        margin-bottom: 0; }
      .clc-container .clc-content .container-content h4 {
        font-size: 18px; }
      .clc-container .clc-content .container-content h6 {
        font-size: 16px;
        font-weight: normal;
        margin-top: 0; }
      .clc-container .clc-content .container-content p {
        line-height: 1.8em;
        margin: 0 0 20px; }
      .clc-container .clc-content .container-content ul {
        margin: 4px 0 30px; }
        .clc-container .clc-content .container-content ul li {
          padding: 4px 0; }
    .clc-container .clc-content .content {
      margin: 0 auto;
      min-height: calc(100% - 135px); }
      .clc-container .clc-content .content > div {
        padding: 30px; }
      .clc-container .clc-content .content .accessibility, .clc-container .clc-content .content .terms {
        max-width: 860px;
        margin: 0 auto; }
      .clc-container .clc-content .content .accessibility a {
        padding: 0; }
      .clc-container .clc-content .content .not-found {
        max-width: 860px;
        margin: 0 auto; }
      .clc-container .clc-content .content .attorneys {
        max-width: 840px; }
        .clc-container .clc-content .content .attorneys .banner {
          background-image: url(/img/attorneys/banner.jpg); }
      .clc-container .clc-content .content .contact {
        margin: 0 auto;
        width: 400px; }
        .clc-container .clc-content .content .contact h1 {
          text-align: center; }
        .clc-container .clc-content .content .contact form {
          display: flex;
          flex-direction: column; }
          .clc-container .clc-content .content .contact form input,
          .clc-container .clc-content .content .contact form textarea {
            border-width: 2px;
            border-style: solid;
            border-radius: 4px;
            color: #444;
            font-size: 16px;
            margin-bottom: 14px;
            padding: 8px;
            width: 100%; }
            .clc-container .clc-content .content .contact form input::placeholder,
            .clc-container .clc-content .content .contact form textarea::placeholder {
              color: #999; }
          .clc-container .clc-content .content .contact form label {
            color: #999;
            font-size: 14px;
            text-transform: uppercase; }
          .clc-container .clc-content .content .contact form .fullname {
            display: flex;
            flex-direction: row;
            justify-content: space-between; }
            .clc-container .clc-content .content .contact form .fullname div {
              width: 48%; }
      .clc-container .clc-content .content .feature {
        margin: auto;
        max-width: 940px;
        text-align: center; }
        .clc-container .clc-content .content .feature .banner {
          align-items: center;
          background-position: center;
          background-size: cover;
          display: flex;
          flex-direction: column;
          height: 320px;
          left: 0;
          position: absolute;
          top: 0;
          width: 100%; }
          .clc-container .clc-content .content .feature .banner p {
            color: #fff;
            font-size: 20px;
            font-weight: 700;
            line-height: 2em;
            margin: auto;
            max-width: 580px;
            padding: 0 20px; }
        .clc-container .clc-content .content .feature .container {
          margin-top: 340px; }
          .clc-container .clc-content .content .feature .container .action {
            font-weight: bold;
            margin: 20px auto 10px;
            max-width: 480px; }
          .clc-container .clc-content .content .feature .container button, .clc-container .clc-content .content .feature .container a.button {
            display: block;
            line-height: 28px;
            margin: 6px auto;
            text-decoration: none;
            width: 220px; }
      .clc-container .clc-content .content .home h1 {
        text-align: center; }
      .clc-container .clc-content .content .home p {
        max-width: 760px;
        text-align: center;
        margin: 10px auto; }
      .clc-container .clc-content .content .home .feature-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 60px 0 0; }
        .clc-container .clc-content .content .home .feature-container .feature {
          margin: 0 auto;
          max-width: 550px;
          width: calc(50% - 20px); }
          .clc-container .clc-content .content .home .feature-container .feature h2 {
            text-align: center; }
          .clc-container .clc-content .content .home .feature-container .feature > div {
            background: #777;
            overflow: hidden;
            position: relative; }
            .clc-container .clc-content .content .home .feature-container .feature > div a {
              padding: 0; }
              .clc-container .clc-content .content .home .feature-container .feature > div a:focus img {
                opacity: .1; }
            .clc-container .clc-content .content .home .feature-container .feature > div img {
              display: block;
              transition: transform 0.3s;
              width: 100%; }
            .clc-container .clc-content .content .home .feature-container .feature > div:hover img {
              transform: scale(1.08);
              transition: transform 0.3s; }
            .clc-container .clc-content .content .home .feature-container .feature > div .title {
              color: #fff;
              position: absolute;
              left: 50%;
              text-transform: uppercase;
              top: 40%;
              transform: translate(-50%, -60%); }
          .clc-container .clc-content .content .home .feature-container .feature p {
            margin: 16px auto 60px;
            width: 82%; }
      .clc-container .clc-content .content .identity-theft .banner {
        background-image: url(/img/identity-theft/banner.jpg); }
      .clc-container .clc-content .content .identity-theft .container .container-content td {
        vertical-align: top;
        width: calc(100% / 3); }
      .clc-container .clc-content .content .mediators .banner {
        background-image: url(/img/mediators/banner.jpg); }
      .clc-container .clc-content .content .mediators .container .action {
        max-width: 580px; }
  .clc-container .clc-footer {
    box-shadow: 0px -2px 5px 0px rgba(0, 0, 0, 0.2);
    height: 135px; }
    .clc-container .clc-footer .footer {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      margin: 0 auto;
      padding: 30px; }
      .clc-container .clc-footer .footer .links {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        height: 70px;
        justify-content: space-around; }
        .clc-container .clc-footer .footer .links span {
          color: #444; }
          .clc-container .clc-footer .footer .links span a {
            color: inherit;
            font-weight: 400;
            padding: 0 24px;
            text-decoration: none;
            text-transform: uppercase; }
      .clc-container .clc-footer .footer .copyright {
        font-size: 0.9rem; }

@media all and (min-width: 1200px) {
  .clc-container .clc-header .header,
  .clc-container .clc-content .content,
  .clc-container .clc-footer .footer {
    width: 1200px; } }

@media all and (max-width: 829px) {
  .clc-container .clc-header .header,
  .clc-container .clc-footer .footer {
    padding: 10px 15px;
    width: 100%; }
  .clc-container .clc-content {
    -webkit-overflow-scrolling: touch; }
    .clc-container .clc-content .content > div {
      padding: 10px 15px 40px 15px; }
      .clc-container .clc-content .content > div.videos, .clc-container .clc-content .content > div.articles {
        padding-bottom: 0; }
    .clc-container .clc-content .content .checkup .results {
      flex-direction: column; }
      .clc-container .clc-content .content .checkup .results .chart {
        margin: 30px auto; }
      .clc-container .clc-content .content .checkup .results .explanation {
        margin: 0 auto;
        width: 80%; }
        .clc-container .clc-content .content .checkup .results .explanation h3 {
          text-align: center; }
  .clc-container .clc-header .header {
    align-items: center; }
    .clc-container .clc-header .header .header-logo a img {
      height: 44px; } }

@media all and (max-width: 560px) {
  .clc-container .clc-content .content .home .feature-container {
    justify-content: center; }
    .clc-container .clc-content .content .home .feature-container .feature {
      width: 100%; }
  .clc-container .clc-content .content .checkup {
    margin-top: 10px; }
    .clc-container .clc-content .content .checkup h3 {
      padding-bottom: 10px; } }

@media all and (max-width: 499px) {
  .button {
    width: 100%; }
  .clc-container .clc-content .content .checkup .results .explanation {
    width: 90%; }
  .clc-container .clc-content .content .contact {
    width: 100%; }
    .clc-container .clc-content .content .contact form .fullname {
      display: flex;
      flex-direction: column;
      width: 100%; }
      .clc-container .clc-content .content .contact form .fullname div {
        width: 100%; }
  .clc-container .clc-footer .footer .links {
    width: 100%;
    flex-direction: column; } }
